@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Gmarket";
    src: url("src/fonts/GmarketSansTTFMedium.ttf");
}

@font-face {
    font-family: "BlackhanSans";
    src: url("src/fonts/BlackHanSans-Regular.ttf") format("truetype");
  }

  
@font-face {
    font-family: "esamanru";
    src: url("src/fonts/esamanru Medium.ttf");
  }
